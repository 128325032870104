/* horizontal table */
/* title */
.lhc-layout-horizontal .lhc-form-horizontal-table-title {
  background: #e6f1ff; /*#E8E8E8;*/
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 0 0 1px;
  text-align: left;

  border-width: 0;
  border-top: 2px solid white; /* leave space between headers */
  padding: 4px 2px 4px 2px;
}
/* column headers in tables */
.lhc-layout-horizontal .lhc-form-horizontal-table-header {
  overflow: hidden;
  vertical-align: top;
  background: none repeat scroll 0 0 #E8E8E8;
  padding: 4px 2px;
  text-align: left ;
  color: #666666;
  font-weight: 400;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.lhc-layout-horizontal .lhc-form-horizontal-table td.lhc-de-button {
  padding-left: 2px;
  border-right: none;
}
.lhc-layout-horizontal table.lhc-form-horizontal-table {
  border: none;
  margin: 2px 4px 0 1px;
}
.lhc-layout-horizontal .lhc-form-horizontal-table tr {
  border: none;
}
.lhc-layout-horizontal .lhc-form-horizontal-table tr td {
  border-right: none;
  border-left: none;
}
.lhc-layout-horizontal .lhc-form-horizontal-table .lhc-de-button {
  width: 2.1em;
  min-width: 2em;
}

.lhc-layout-horizontal div.lhc-de-input-unit {
  order: 1;
  flex-grow: 1;
  flex-shrink: 1;

}

.lhc-layout-horizontal lhc-item-question {
  border: 0;
  margin: 0;
}
.lhc-layout-horizontal table {
  border-spacing: 0;
  border-collapse: collapse;
}
.lhc-layout-horizontal td.lhc-item-question {
  padding: 0;
}

/* end of horizontal table */
