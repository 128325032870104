/* lforms widget styles */
.lhc-form {
  font-family: "Arial";
  font-size: 14px;
  background-color: #FFFFFF;
}

/* no spinner */
.lhc-form input[type=number]::-webkit-outer-spin-button,
.lhc-form input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.lhc-form input[type=number] {
  -moz-appearance:textfield;
}

/* Keep the font-weight on the questions normal */
.lhc-form label {
  font-weight: normal;
  margin-bottom: 0;
}

/* form title */
.lhc-form-title {
  background-color: #458AC5;
  color: #FFF;
  overflow: hidden;
  padding: 8px;
  margin: 4px 0;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.lhc-form-title a {
  color: #FFFFFF;
}
.lhc-form-title .lhc-item-question {
  font-size: 120%;
  /* Center title within containing div */
  vertical-align: text-top;
}

/* lhc-form-header */

/* table structure in the forms */
.lhc-form table {
  /*border-collapse: collapse;*/
  padding: 0;
  width: 100%;
  table-layout: fixed;
}


/* input fields */
.lhc-form input[type="text"],
.lhc-form input[type="number"] {
  border: 1px solid #ced5d9 ;
  width: 100%;
  height: 28px;
  min-height: 14px;
  overflow: hidden;
  padding: 2px 2px 2px 4px;
  font-size: 90%;
  font-weight: 700;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.lhc-form textarea {
  border: 1px solid #ced5d9 ;
  width: 100%;
  height: 1em; /* initial height (for firefox)*/
  min-height: 28px;
  overflow: visible;
  padding: 2px 2px 2px 4px;
  font-size: 90%;
  font-weight: 700;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  vertical-align: bottom; /* remove the extra padding at the bottom of the containing div. */
  /*display:block; !* remove the extra padding at the bottom of the containing div. (solution #2) *!*/
  resize: vertical; /* not allow user to change width */

}
.lhc-form input:focus,
.lhc-form textarea:focus,
.lhc-form .ant-picker-input>input:focus {
  background-color: #FFF8C6;
}

.lhc-form input[readonly],
.lhc-form textarea[readonly],
.lhc-form .ant-picker-input>input[readonly] {
  background-color: #E8E8E8;
}

.lhc-form .lhc-item-disabled-protected {
  background-color: #E8E8E8;
}

span.loading-indicator-container {
  width: 100%;
}

/* buttons in tables */
.lhc-de-button {
  width: 22px;
}
.lhc-form .lhc-button {
  border: 0 none;
  cursor: pointer;
  overflow: visible;
  padding: 0;
  position: relative;
  background-color: #066aa0; /*#458AC5;*/
}
.lhc-form .lhc-float-button {
  min-width: 22px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  font-weight: bold;
  color: white;
  padding: 1px;
  padding-left: 0.5em;
  padding-right: 0.5em;
  margin-bottom: 2px;
}
.lhc-form .lhc-help-button,
.lhc-form .lhc-legal-button,
.lhc-form .lf-control-button,
.lhc-form .lhc-copyright-button {
  padding: 0;
  margin-right: 2px;
  margin-left:2px;
  vertical-align: middle;
  background-color: transparent;
  color: #066aa0; /*#458AC5;*/
  border: none;
}
.lhc-form .lhc-help-button:hover,
.lhc-form .lhc-legal-button:hover,
.lhc-form .lf-control-button:hover,
.lhc-form .lhc-copyright-button:hover {
  color: #0087fd;
  cursor: pointer;
}
.lhc-form .lhc-form-title .lhc-help-button,
.lhc-form .lhc-form-title .lhc-legal-button,
.lhc-form .lhc-form-title .lf-control-button,
.lhc-form .lhc-form-title .lhc-copyright-button {
  background-color: inherit;
  color: white;
  font-weight: bold;
}
.lhc-form .lhc-form-title .lhc-help-button:hover,
.lhc-form .lhc-form-title .lhc-legal-button:hover,
.lhc-form .lhc-form-title .lf-control-button:hover,
.lhc-form .lhc-form-title .lhc-copyright-button:hover {
  color: #b4fda7;
}
.lhc-form .lhc-help-button i,
.lhc-form .lhc-legal-button i,
.lhc-form .lf-control-button i,
.lhc-form .lhc-copyright-button i{
  font-size: medium;
}

.lhc-form-title .popover {
  color: #333333;
}

/* name field */
.lhc-form .lhc-de-label span.lf-prompt {
  font-style: italic;
  font-size: 75%;
}
.lhc-form .lhc-de-label span.lf-sn{
  -moz-border-radius: 4px;
  border-radius: 4px;
  background-color: #066aa0;
  font-weight: bold;
  color: white;
  padding: 4px 8px;
  margin-right: 4px;
}
.lhc-form .lhc-de-label span.lhc-item-question{
  padding: 0 2px 0 2px;
}

/* required indicator */
.lhc-form .lhc-required {
  color: darkred;
  font-weight: bold;
}

/* form stop sign */
.lhc-form .stopped {
  text-align: center;
  width:500px;
  height: 50px;
  margin-left: 30%;
  margin-right: 30%;
  margin-bottom: 20px;
  vertical-align: top;
}

/* active row */
.lhc-item-question.lhc-active-row {
  background-color: #AFEEEE;
}

/* units */
.lhc-form input.units {
  color: #333333;
  font-weight: 400;
}

/* restore autocomplete default styles */
/* Also, unit fields sometimes have answer lists and sometimes not, so set the
 * padding there two for consistent field widths. */
 .lhc-form input.ansList,
 .lhc-form input.search_field {
  padding-right: 14px;
}
/* reset autocomplete default styles for unit field*/
.lhc-form lhc-unit input.ansList,
.lhc-form lhc-unit input.ansList:focus {
  padding-right: 2px;
}

/* visible skip logic target items have different styles */
.lhc-form-body .lhc-form-table-row.target-show td {
  color: #015DAD;
}

/*** button row ***/
.lhc-form .lhc-form-table-row.button-row {
  background: none;
  padding: 2px 0;
}

.lhc-form.lf-template-table .lhc-layout-horizontal,
.lhc-form.lf-template-table .lhc-layout-matrix {
  padding-right: 6px;
}

/* not to show title when the entire form is in horizontal or matrix layout */
.lf-top-section .lhc-layout-horizontal .lhc-form-horizontal-table-title,
.lf-top-section .lhc-layout-matrix .lhc-form-matrix-table-title {
  display: none;
}

.lhc-form-view {
  margin: 15px;
}
.lhc-form-body {
  border: 1px solid #ced5d9;
  padding: 4px 0;
  margin: 0 0 2px 0;

  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;

}


/* boarder, padding and margin */
/* rows in tables */
.lhc-form .lhc-form-table-row {
  border: solid;
  border-color: #ced5d9;
  border-width: 1px 0 0 0;
  margin: 0 4px 0 2px;
}


/* section header */
.lhc-form .lhc-form-table-row.lhc-item-group {
  background: #e6f1ff; /*#E8E8E8;*/
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 4px 0 2px;

  border-width: 0;
  border-top: 2px solid white; /* leave space between headers */
}
.lhc-form .lhc-form-table-row.lhc-item-group .lhc-de-label-button  {
  padding: 4px 0 4px 0;
}

/* title row */
.lhc-form .lhc-item.lhc-datatype-TITLE  {
  padding-top: 4px;
  padding-bottom: 4px;
}
.lhc-form .lhc-item.lhc-datatype-TITLE .lhc-item-display {
  background-color: #e6f1ff; /*rgb(67,115,156);*/
  border-radius: 4px;
  box-sizing: border-box;
  margin: 0 4px 0 2px;
  border-width: 0;
  border-top: 2px solid white;
  padding: 4px 2px 4px 2px;
}
/* disabled and protected display */
.lhc-form .lhc-item.lhc-datatype-TITLE.lhc-item-disabled-protected .lhc-item-display {
  background-color: #E8E8E8;
}

.lhc-form .lhc-item-group-title {
  background: #e6f1ff;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0 4px 0 2px;
  border-width: 0;
  border-top: 2px solid white;
  padding: 4px 2px 4px 2px;
}
/* disabled and protected group */
.lhc-form .lhc-item-disabled-protected .lhc-item-group-title,
.lhc-form .lhc-item-disabled-protected .lhc-form-matrix-table-title,
.lhc-form .lhc-item-disabled-protected .lhc-form-horizontal-table-title {
  background-color: #E8E8E8;
}

/* first row in a group or in the form */
.lhc-form lhc-item.lhc-first-item > lhc-item-question{
  border-top: none;
}

/* prefix */
.lhc-form .prefix {
  padding-right: 0.25em; /* space between the prefix and the question */
}

/* popover is attached to page under <body> */
div.ant-popover-title {
  background-color: #f7f7f7;
}
div.cdk-overlay-pane {
  max-width: 66%;
}
/* show newlines in popover*/
.lhc-popover-help .ant-popover-inner-content,
.lhc-popover-legal .ant-popover-inner-content,
.lhc-popover-copyright .ant-popover-inner-content,
.lhc-form .lhc-inline-help,
.lhc-form .lhc-inline-legal,
.lhc-form .lhc-inline-copyright {
  white-space: pre-wrap;
  width: auto;
}
/* div.ant-popover {
  top: 10px;
}
div.ant-popover-arrow:after,
div.ant-popover-inner-content {
  background-color: #fcf8e3;
}

.lhc-validate-class.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow {
  bottom: 6.2px;
  box-shadow: 3px 3px 7px rgb(0 0 0 / 7%);
  border-color: transparent #fcf8e3 #fcf8e3 transparent;
} */

/* screen reader log */
screen_reader_only {
  height: 0;
  width: 0;
  overflow: hidden;
  position: fixed;
  left: -1000px;
  top: -1000px;
}


.lhc-button-padding {
  padding-left: 4px;
}

/* disabled items */
/* remove the delete button on disabled mulitple selection autocomplete */
.lhc-form .lhc-item-readyonly button {
  display: none;
}
.lhc-form .ant-checkbox-disabled+span,
.lhc-form .ant-radio-disabled+span,
.lhc-form input:disabled,
.lhc-form .ant-picker-input>input-disabled,
.lhc-form .ant-picker-input>input[disabled],
.lhc-form .lhc-item-readyonly {
  color: rgba(0,0,0,.75);
  cursor: not-allowed;
  /* opacity: 0.5; */
}
.lhc-form .lhc-button:disabled {
  color: #666666;
  background-color: #cccccc;
  cursor: not-allowed;
}
